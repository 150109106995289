<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('import.add') }}</h2>
    <div class="c-main">
      <form class="c-main--flex-1">
        <div class="o-layout">
          <div class="o-layout__item">
            <ul class="o-list o-list-bare c-input--extra-info">
              <li class="o-list__item">{{ $t('import.select.type.info') }}</li>
            </ul>
          </div>
          <div class="o-layout__item" v-for="type in types" :key="type.route">
            <sceRouteButton :route="{ name: type.route}" :label="type.label"
                            class="c-button--primary c-button--large wide"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find';

export default {
  name: "import-add",
  data: function () {
    return {
      types: [],
    };
  },
  computed: {
    user: function () {
      return this.$store.state.user.instance;
    },
    eventUser: function () {
      const event = this.$store.state.events.current
      const user = this.$store.state.user.instance;
      if (user.id === event.owner_id) {
        return user;
      }
      return find(this.$store.state.users.items, item => item.id === event.owner_id);
    },
  },
  created: function () {
    let types = [];

    if (this.eventUser.configuration && this.eventUser.configuration.federationImport) {
      types.push({
        route: 'admin.event.discipline.import.add.federation',
        label: 'import.add.fromFederation',
      });
    }

    types.push({
      route: 'admin.event.discipline.import.add.csv',
      label: 'import.add.fromCsv',
    });
    types.push({
      route: 'admin.event.discipline.import.add.sce-event',
      label: 'import.add.fromSceEvent',
    });

    this.types = types;
  },
};
</script>

<style scoped>
.wide {
  width: 100%;
}
</style>
