<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('imports')}}</h2>
    <grid :config="{searchFields: ['name']}" :data="imports">
      <template v-slot:gridAdd>
        <router-link exact :to="{ name: 'admin.event.discipline.import.add'}"
                     class="c-button c-button--secondary c-button--large c-button--icon">
          <svg role="img" class="c-button__icon">
            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
          </svg>
          <span class="c-button__label">{{$t("import.add")}}</span>
        </router-link>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-medium">
          <div class="o-layout__item u-2-of-4-at-medium">
            {{$t('filename')}}
          </div>
          <div class="o-layout__item u-1-of-4-at-medium">
            {{$t('time')}}
          </div>
          <div class="o-layout__item u-1-of-4-at-medium">
            {{$t('status')}}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-2-of-4-at-medium c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.event.discipline.import.view',
           params: {importId: element.id}}">
            {{element.filename}}
          </router-link>
        </div>
        <div class="o-layout__item u-1-of-4-at-medium">
          {{formatTime(element.date)}}
        </div>
        <div class="o-layout__item u-1-of-4-at-medium">
          {{$t('import.status.' + element.status)}}
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
  import sortBy from 'lodash/sortBy';

  const moment = require('moment');

  export default {
    name:'imports-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      imports: function() {
        return sortBy(this.$store.state.imports.items, 'date').reverse();
      },
    },
    methods: {
      formatTime: function(time) {
        return moment(time).format('DD-MM-YYYY HH:mm');
      }
    }
  };
</script>

<style>
</style>
