<template>
  <div class="o-layout u-margin-bottom-medium">
    <div class="o-layout__item">
      <h3 class="c-title c-title--page-section u-margin-top-medium">
        {{assignFields ? $t('import.choosefields') : $t('import.chooseSections')}}
      </h3>
    </div>
    <template v-for="(group, key) in sectionFields" >
      <div class="o-layout__item" :key="'group-' + key">
        <sceCheckBox v-model="group.import" :name="'group-' + key" :label="'import.group.' + key"
                     class="c-title--page-section" :disabled="! group.enabled"/>
      </div>
      <template v-if="group.enabled && group.import && assignFields">
        <div v-for="(field, field_key) in group.fields" class="o-layout__item u-1-of-3-at-small u-1-of-4-at-large"
             :key="'field-'+key+'-'+field_key">
          <sceSelect v-model="field.value" :name="'field-' + key +'-'+field_key"
                     :label="'import.fieldname.' + field_key"
                     :options="fieldLabels"
                     :config="{required: field.required, emptyLabel: 'import.field.omit', optionId: 'name',
                      optionLabel: 'name', localized: false}"/>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import clone from 'lodash/clone';
  import cloneDeep from 'lodash/cloneDeep';
  import filter from 'lodash/filter';
  import forEach from 'lodash/forEach';
  import forOwn from 'lodash/forOwn';
  import intersection from 'lodash/intersection';

  export default {
    name: "import-fields",
    props: ['value', 'assignFields', 'discipline', 'fieldLabels', 'sections'],
    data: function() {
      return {
        sectionFields: {},
        valid: this.value.valid || false,
      }
    },
    created: function() {
      this.initFields();
    },
    watch: {
      sectionFields: {
        immediate: true,
        deep: true,
        handler: function() {
          let validSelection = true;
          forOwn(this.sectionFields, (group) => {
            let enabled = true;
            forEach(group.depends, depend => {
              const dGroup = this.sectionFields[depend];
              if (! (dGroup.enabled && dGroup.import)) {
                enabled = false;
              }
            });

            if (group.enabled && group.import && this.assignFields) {
              forEach(group.sectionFields, field => {
                if (field.required && ! field.value) {
                  validSelection = false;
                }
              })
            }
            group.enabled = enabled;
          });

          this.valid = validSelection;
          this.updateFields();
        }
      },
    },
    methods: {
      initFields: function() {
        let fields = {};
        // add club fields
        fields.club = {
          import: true,
          enabled: true,
          depends: [],
          fields: {
            name: {
              value: undefined,
              required: true
            },
            externalId: {
              value: undefined,
              required: false
            },
            country: {
              value: undefined,
              required: false
            },
            region: {
              value: undefined,
              required: false
            }
          }
        };

        // add club member fields
        fields.member = {
          import: true,
          enabled: true,
          depends: ['club'],
          fields: {
            lastname: {
              value: undefined,
              required: false,
            },
            firstname: {
              value: undefined,
              required: false,
            },
            fullname: {
              value: undefined,
              required: false,
            },
            gender: {
              value: undefined,
              required: true,
            },
            dob: {
              value: undefined,
              required: false,
            },
            judge: {
              value: undefined,
              required: false,
            },
            externalId: {
              value: undefined,
              required: false,
            }
          }
        };

        // add group fields
        if (this.discipline.group) {
          fields.group = {
            import: ! this.discipline.individual,
            enabled: true,
            depends: ['member'],
            fields: {
              name: {
                value: undefined,
                required: true,
              },
              externalId: {
                value: undefined,
                required: false,
              }
            }
          };
        }

        // add team fields
        if (this.discipline.team) {
          fields.team = {
            import: false,
            enabled: true,
            depends: ['member'],
            fields: {
              name: {
                value: undefined,
                required: true,
              },
              externalId: {
                value: undefined,
                required: false,
              }
            }
          };
        }

        fields.round = {
          import: false,
          enabled: true,
          depends: [],
          fields: {},
        }

        fields.category = {
          import: true,
          enabled: true,
          depends: [],
          fields: {
            name: {
              value: undefined,
              required: true,
            },
            template: {
              value: undefined,
              required: false,
            },
            externalId: {
              value: undefined,
              required: false,
            },
            competitionId: {
              value: undefined,
              required: false,
            }
          }
        };

        fields.participation = {
          import: true,
          enabled: true,
          depends: ['member', 'category'],
          fields: {
            bib: {
              value: undefined,
              required: false,
            },
            reserve: {
              value: undefined,
              required: false,
            },
            guest: {
              value: undefined,
              required: false,
            },
            subdivision: {
              value: undefined,
              required: false,
            },
            exercises: {
              value: undefined,
              required: false,
            },
            externalId: {
              value: undefined,
              required: false,
            },
          }
        };

        if (this.discipline.team) {
          fields.teamParticipation = {
            import: false,
            enabled: false,
            depends: ['team', 'participation'],
            fields: {
              bib: {
                value: undefined,
                required: false,
              },
              reserve: {
                value: undefined,
                required: false,
              },
              guest: {
                value: undefined,
                required: false,
              },
              externalId: {
                value: undefined,
                required: false,
              }
            }
          };
        }

        fields.session = {
          import: false,
          enabled: true,
          depends: [],
          fields: {
            name: {
              value: undefined,
              required: true,
            },
            date: {
              value: undefined,
              required: true,
            },
            externalId: {
              value: undefined,
              required: false,
            },
          }
        };

        fields.order = {
          import: false,
          enabled: true,
          depends: ['session', 'participation'],
          fields: {
            set: {
              value: undefined,
              required: false,
            },
            rotation: {
              value: undefined,
              required: false,
            },
            block_index: {
              value: undefined,
              required: false,
            },
          }
        }

        fields.exercise = {
          import: false,
          enabled: true,
          depends: ['participation', 'order'],
          fields: {},
        }

        if (this.discipline.rotationType === 'mixed') {
          fields.order.fields.exercise = {
            value: undefined,
            required: false,
          };
        }

        if (this.sections) {
          let sections = clone(this.sections);

          let removed = true;
          while (removed) {
            removed = false;

            sections = filter(sections, section => {
              const group = fields[section];
              let remove = false;
              if (! group) {
                remove = true;
              } else {
                if (intersection(group.depends, sections).length < group.depends.length) {
                  remove = true;
                }
              }

              removed = removed || remove;
              return ! remove;
            });
          }

          const newFields = {};
          forOwn(fields, (group, key) => {
            if (sections.includes(key)) {
              newFields[key] = group;
            }
          });
          fields = newFields;
        }

        this.sectionFields = fields;
      },
      updateFields: function() {
        this.$emit('input', {fields: cloneDeep(this.sectionFields), valid: this.valid});
      },
    },
  }
</script>

<style scoped>

</style>
